[data-fr-theme="light"] {
  --categories-color: var(--green-menthe-main-548);
  --documents-color: transparent;
  --geographical-categories-color: var(--blue-ecume-main-400);
  --geographicalcategories-color: var(--blue-ecume-main-400);
  --grey-card: #F9F8F6;
  --legal-categories-color: var(--green-archipel-main-557);
  --legalcategories-color: var(--green-archipel-main-557);
  --official-texts-color: var(--purple-glycine-main-494);
  --officialtexts-color: var(--purple-glycine-main-494);
  --persons-color: var(--orange-terre-battue-main-645);
  --prizes-color: var(--blue-ecume-main-400);
  --projects-color: var(--brown-caramel-main-648);
  --structures-color: var(--yellow-tournesol-main-731);
  --supervising-ministers-color: var(--yellow-moutarde-main-679);
  --supervisingministers-color: var(--yellow-moutarde-main-679);
  --terms-color: var(--beige-gris-galet-main-702);
  --users-color: #FF0000;
}
[data-fr-theme="dark"] {
  --categories-color: var(--green-menthe-main-548);
  --documents-color: transparent;
  --geographical-categories-color: var(--blue-ecume-main-400);
  --geographicalcategories-color: var(--blue-ecume-main-400);
  --grey-card: #232322;
  --legal-categories-color: var(--green-archipel-main-557);
  --legalcategories-color: var(--green-archipel-main-557);
  --official-texts-color: var(--purple-glycine-main-494);
  --officialtexts-color: var(--purple-glycine-main-494);
  --persons-color: var(--orange-terre-battue-main-645);
  --prizes-color: var(--blue-ecume-main-400);
  --projects-color: var(--brown-caramel-main-648);
  --structures-color : var(--yellow-tournesol-main-731);
  --supervising-ministers-color : var(--yellow-moutarde-main-679);
  --supervisingministers-color : var(--yellow-moutarde-main-679);
  --terms-color: var(--beige-gris-galet-main-702);
  --users-color: #FF0000;
}

:root {
  --facebook-icon-color: #3A5794;
  --github-icon-color: inherit;
  --gitlab-icon-color: #E24329;
  --instagram-icon-color: #F7017A;
  --linkedin-icon-color: #0584C3;
  --twitch-icon-color: #8C44F7;
  --twitter-icon-color: #1C9CEA;
  --youtube-icon-color: #FD0100;
}
