.fr-password-info {
  color: var(--info-main-525);
  margin: .25rem .75rem !important;
}

.fr-password-success {
  color: var(--success-main-525);
  margin: .25rem .75rem !important;
}

.fr-password-error {
  color: var(--error-main-525);
  margin: .25rem .75rem !important;
}