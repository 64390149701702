// SAVE CONFIG FOR THEMING IN HIGHCHARTS v11

// @import 'highcharts/css/highcharts.css';

// [data-fr-theme="dark"] {
//   /* Chart background, point stroke for markers and columns etc */
//   --highcharts-background-color: #161616;

//   // /* Colors for data series and points. */
//   --highcharts-color-0: #2b908f;
//   --highcharts-color-1: #90ee7e;
//   --highcharts-color-2: #f45b5b;
//   --highcharts-color-3: #7798bf;
//   --highcharts-color-4: #aaeeee;
//   --highcharts-color-5: #ff0066;
//   --highcharts-color-6: #eeaaee;
//   --highcharts-color-7: #55bf3b;
//   --highcharts-color-8: #df5353;
//   --highcharts-color-9: #7798bf;
  
//   // /* Neutral colors */
//   --highcharts-neutral-color-100: #ffffff;
//   --highcharts-neutral-color-80: #e0e0e3;
//   --highcharts-neutral-color-60: #e0e0e3;
//   --highcharts-neutral-color-40: #666666;
//   --highcharts-neutral-color-20: #606063;
//   --highcharts-neutral-color-10: #707073;
//   --highcharts-neutral-color-5: #505053;
//   --highcharts-neutral-color-3: #303033;
  
//   /* Highlight colors https://jsfiddle.net/highcharts/pukradvh/ */
//   --highcharts-highlight-color-100: rgb(166, 199, 237);
//   --highcharts-highlight-color-80: rgb(134, 165, 201);
//   --highcharts-highlight-color-60: rgb(102, 131, 164);
//   --highcharts-highlight-color-20: rgb(39, 64, 91);
//   --highcharts-highlight-color-10: rgb(23, 47, 73);

//   .highcharts-axis-labels text {
//     color: #ffffff;
//   }
// }

// [data-fr-theme="light"] {
//   /* Chart background, point stroke for markers and columns etc */
//   /* Colors for data series and points */
//     --highcharts-color-0: #2caffe;
//     --highcharts-color-1: #544fc5;
//     --highcharts-color-2: #00e272;
//     --highcharts-color-3: #fe6a35;
//     --highcharts-color-4: #6b8abc;
//     --highcharts-color-5: #d568fb;
//     --highcharts-color-6: #2ee0ca;
//     --highcharts-color-7: #fa4b42;
//     --highcharts-color-8: #feb56a;
//     --highcharts-color-9: #91e8e1;
  
//     --highcharts-background-color: #ffffff;
  
//     --highcharts-neutral-color-100: #000000;
//     --highcharts-neutral-color-80: #333333;
//     --highcharts-neutral-color-60: #666666;
//     --highcharts-neutral-color-40: #999999;
//     --highcharts-neutral-color-20: #cccccc;
//     --highcharts-neutral-color-10: #e6e6e6;
//     --highcharts-neutral-color-5: #f2f2f2;
//     --highcharts-neutral-color-3: #f7f7f7;
  
//     --highcharts-highlight-color-100: #0022ff;
//     --highcharts-highlight-color-80: #334eff;
//     --highcharts-highlight-color-60: #667aff;
//     --highcharts-highlight-color-20: #ccd3ff;
//     --highcharts-highlight-color-10: #e6e9ff;
// }

.highcharts-background {
  fill: var(--background-default-grey);
}

.highcharts-xaxis-labels text,
.highcharts-yaxis-labels text {
  // stroke: var(--text-default-grey) !important;
  fill: var(--text-default-grey) !important;
}

.highcharts-legend-item text {
  fill: var(--text-default-grey) !important;
}

.highcharts-title,
.highcharts-subtitle {
  fill: var(--text-default-grey) !important;
  font-family: var(--font-family) !important;
}