
/**
 * Styled conponents loading spinner.
 * credits to jdacosta from https://codepen.io/jdacosta/pen/dobzpM
 */

 
.spinner {
  @keyframes internal {
    0% {
      stroke-dashoffset: 187;
    }
  
    25% {
      stroke-dashoffset: 80;
    }
  
    100% {
      stroke-dashoffset: 187;
      transform: rotate(360deg);
    }
  }
  
  @keyframes external {
    0% {
      stroke-dashoffset: 312;
      transform: rotate(70deg);
    }
  
    60% {
      stroke-dashoffset: -312;
    }
  
    100% {
      stroke-dashoffset: -312;
      transform: rotate(450deg);
    }
  }
  width: 64px;
  height: 64px;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;
  justify-self: center;
  display: flex;

  .internal-circle,
  .external-circle {
    stroke-dashoffset: 0;
    transform-origin: center;
    opacity: 1;
  }

  .internal-circle {
    stroke: var(--red-marianne-425);
    stroke-dasharray: 187;
    animation: internal 1s ease-in-out infinite;
  }

  .external-circle {
    stroke: var(--blue-france-113);
    stroke-dasharray: 312;
    animation: external 1s linear infinite;
  }
}

.spinner-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(var(--ground) + 1000);
  cursor: pointer;
  background-color: rgba(#161616, 0.85);
  color: var(--text-inverted-grey);
}
