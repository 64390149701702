* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}