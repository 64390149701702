.avatar-img {
  width: 100%;
  border-radius: 99999px;
}
.avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 99999px;
  > SPAN {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99999px;
    width: 100%;
    height: 100%;
    background-color: var(--green-emeraude-main-632);
  }
}
