@keyframes notice-mount {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes notice-unmount {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes notice-unfill {
  from {
    width: 100%;
  }

  to {
    width: 0%
  }
}

.notice {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  animation: notice-mount .3s;
}

.notice-info {
  --idle: #0000;
  --hover: var(--background-contrast-info-hover) !important;
  --active: var(--background-contrast-info-active) !important;
  background-color: var(--background-contrast-info) !important;
  color: var(--text-default-info) !important;

  .fr-notice__body::before {
    content: "\ee59";
    font-size: 1.4rem;
    line-height: 1.5rem;
    background-color: inherit;
    box-shadow: none;
    font-family: remixicon;
  }
}
.notice-error {
  --idle: #0000;
  --hover: var(--background-contrast-error-hover) !important;
  --active: var(--background-contrast-error-active) !important;
  background-color: var(--background-contrast-error) !important;
  color: var(--text-default-error) !important;

  .fr-notice__body::before {
    content: "\eb97";
    font-size: 1.4rem;
    line-height: 1.5rem;
    background-color: inherit;
    box-shadow: none;
    font-family: remixicon;
  }
}
.notice-warning {
  --idle: #0000;
  --hover: var(--background-contrast-warning-hover) !important;
  --active: var(--background-contrast-warning-active) !important;
  background-color: var(--background-contrast-warning) !important;
  color: var(--text-default-warning) !important;

  .fr-notice__body::before {
    content: "\eca1";
    font-size: 1.4rem;
    line-height: 1.5rem;
    background-color: inherit;
    box-shadow: none;
    font-family: remixicon;
  }
}
.notice-success {
  --idle: #0000;
  --hover: var(--background-contrast-success-hover) !important;
  --active: var(--background-contrast-success-active) !important;
  background-color: var(--background-contrast-success) !important;
  color: var(--text-default-success) !important;

  .fr-notice__body::before {
    content: "\eb81";
    font-size: 1.4rem;
    line-height: 1.5rem;
    background-color: inherit;
    box-shadow: none;
    font-family: remixicon;
  }
}

.notice-btn--close {
  color: inherit !important;
  border-radius: 99999px;
}

.notice-progress-bar {
  animation-name: notice-unfill;
  animation-timing-function: linear;
  animation-play-state: running;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: rgba(0, 0, 18, 0.16);
}
