[data-fr-theme="light"] {
  --jobs-success-color: #32e477;
  --jobs-running-color: #a4b9f7;
  --jobs-scheduled-color: #edb82f;
  --jobs-failed-color: #f7aaaa;

  .selected {
    margin-right: -2rem;
    filter: brightness(95%);
  }
  .selected-div {
    filter: brightness(95%);
  }
}

[data-fr-theme="dark"] {
  --jobs-success-color: #32e477;
  --jobs-running-color: #a4b9f7;
  --jobs-scheduled-color: #edb82f;
  --jobs-failed-color: #f7aaaa;
  .selected {
    margin-right: -2rem;
    filter: brightness(130%);
  }
  .selected-div {
    filter: brightness(130%);
  }
}

.failed-border {
  border-left: .25rem solid var(--jobs-failed-color) !important;
  border-radius: .25rem;
}

.success-border {
  border-left: .25rem solid var(--jobs-success-color) !important;
  border-radius: .25rem;
}

.scheduled-border {
  border-left: .25rem solid var(--jobs-scheduled-color) !important;
  border-radius: .25rem;
}

.running-border {
  border-left: .25rem solid var(--jobs-running-color) !important;
  border-radius: .25rem;
}
.failed-border-right {
  border-right: .25rem solid var(--jobs-failed-color) !important;
  border-radius: .25rem;
}

.success-border-right {
  border-right: .25rem solid var(--jobs-success-color) !important;
  border-radius: .25rem;
}

.scheduled-border-right {
  border-right: .25rem solid var(--jobs-scheduled-color) !important;
  border-radius: .25rem;
}

.running-border-right {
  border-right: .25rem solid var(--jobs-running-color) !important;
  border-radius: .25rem;
}

.job-list {
  margin: -.5rem;
}
.job {
  padding: 0.5rem;
}

.job-code {
  white-space: pre-wrap;
  word-break: break-word;
  // max-height: 200px;
  // overflow-y: scroll;
}
.card-button {
  cursor: pointer !important;
  color: inherit !important;
}
.card-fit-content {
  height: fit-content !important;
}
